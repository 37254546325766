import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import BreadCrumbs from '../components/common/breadcrumbs'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons'

const Testimonials = ({ data, path }) => {

  const breadcrumbs = {
    'Home': '/',
    'Testimonials': '/testimonials',
    [data.nodeTestimonial.title]: null
  }

  return (
    <Layout pageTitle={data.nodeTestimonial.title}>
      <SEO title={data.nodeTestimonial.title} />
      <div className="page-testimonial-details">
        <div className="container is-desktop">
          <div className="page-breadcrumbs notification">
            <BreadCrumbs input={breadcrumbs} />
          </div>
          <div class="content">
            <div className="teaser-testimonial" key={data.nodeTestimonial.id} style={{ paddingBottom: `60px` }}>
              <div className="teaser-title" style={{ width: `80%`, marginBottom: `20px` }}>
                <div>
                  <i>{data.nodeTestimonial.field_testimonial_author_job}</i>, <span>{data.nodeTestimonial.field_testimonial_company_name}</span>
                </div>
              </div>
              <div className="teaser-content columns">
                <div className="column">
                  <div className="testimonial-contents" dangerouslySetInnerHTML={{ __html: data.nodeTestimonial.body.value }}></div>
                </div>
              </div>
              <hr />
              <strong>Testimonial Letter</strong>
              <div className="teaser-letter notification is-warning" style={{ display: `flex`, justifyContent: `space-between` }}>
                <div>
                  <FontAwesomeIcon icon={faFilePdf} />&nbsp;
                  <a href={data.nodeTestimonial.relationships.field_testimonial_letter.localFile.publicURL}>
                    {data.nodeTestimonial.relationships.field_testimonial_letter.localFile.name}
                  </a>
                </div>
                <div style={{ fontWeight: `bold` }}>
                  {data.nodeTestimonial.relationships.field_testimonial_letter.localFile.prettySize}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Testimonials


export const query = graphql`query($path: String!) {
  nodeTestimonial(path: { alias: { eq: $path } }) {
    id
    body {
      value
      format
      processed
      summary
    }
    created
    title
    field_testimonial_author_job
    field_testimonial_company_name
    field_testimonial_date
    path {
      alias
    }
    relationships {
      field_testimonial_letter {
        localFile {
          publicURL
          prettySize
          name
          ext
        }
      }
    }
  }
}`


